.user-input-filter {
    width: 100%;
}
.mobile-input-filter {
    width: 100%;
    height: 400px;
    background: var(--primary-color);
    border-radius: 12px;
    margin: 70px auto auto auto;
    form {
        padding: 70px 30px;
    }
}
.desktop-input-filter {
    display: none;
}

@media (min-width: 768px) {
    .mobile-input-filter {
        display: none;
    }
    .desktop-input-filter {
        display: block;
        width: 600px;
        height: 400px;
        background: var(--primary-color);
        border-radius: 12px;
        margin: 70px auto auto auto;
        form {
            padding: 70px 30px;
        }
    }
}
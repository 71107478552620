.admin-dashboard {
    width: 100%;
    // min-height: 100vh;
    background-color: var(--dashboard-background);
}
.mobile-admin-dashboard {
    width: 100%;
    // padding: 20px;
    // header {
    //     position: absolute;
    //     top: 0;
    //     padding: 1.6em 20%;
    //     color: var(--white-text);
    //     h3 {
    //         font-weight: 500;
    //         font-size: 18px;
    //         line-height: 27px;
    //     }
    // }
    .mobile-overview {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 50px;
        gap: 20px;
        > p {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: var(--black-color);
        }
        .overview-card-top, .overview-card-bottom {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            width: 100%;
        }
        .overview-card {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 20px;
            background: var(--white-text);
            border-radius: 10px;
            padding: 10px;
            width: 153px;
            height: 100px;
            img {
                width: 38px;
                height: 38px;
            }
            > div {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                gap: 7px;
                :first-child {
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 20px;
                    color: var(--black-color);
                }
                :nth-child(2) {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 20px;
                    color: rgba(17, 17, 17, 0.5);
                }
            }
        }
    }
    // .mobile-amount-section {
    //     width: 100%;
    //     height: 400px;
    //     background: var(--primary-color);
    //     border-radius: 12px;
    //     margin: 70px auto auto auto;
    //     form {
    //         padding: 70px 30px;
    //     }
    // }
    .mobile-admin-transactions {
        margin-top: 50px;
        width: 100%;
        header {
            display: flex;
            flex-direction: column;
            span {
                font-weight: 500;
                font-size: 20px;
                line-height: 30px;
                color: var(--black-color);
            }
            >div {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                margin-top: 50px;
                vertical-align: middle;
                div {
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 30px;
                }
            }
        }
    }
}
.desktop-admin-dashboard {
    display: none;
}

@media (min-width: 768px) {
    .mobile-admin-dashboard {
        display: none;
    }
    .desktop-admin-dashboard {
        display: block;
        padding: 40px 60px 40px 23rem;
        header {
            h3 {
                font-weight: 500;
                font-size: 20px;
                line-height: 30px;
                color: var(--black-color);
            }
        }
        .desktop-overview {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: center;
            // justify-content: space-evenly;
            align-items: stretch;
            gap: 20px;
            margin-top: 50px;
            > p {
                flex: 0 0 100%;
                font-weight: 400;
                font-size: 18px;
                line-height: 27px;
                color: var(--black-color);
                margin-bottom: 20px;
            }
            .overview-card {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 20px;
                background: var(--white-text);
                border-radius: 10px;
                padding: 10px;
                width: 253px;
                height: 100px;
                > div {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    gap: 7px;
                    :first-child {
                        font-weight: 500;
                        font-size: 18px;
                        line-height: 27px;
                        color: var(--black-color);
                    }
                    :nth-child(2) {
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 27px;
                        color: rgba(17, 17, 17, 0.5);
                    }
                }
            }
        }
        // .desktop-amount-section {
        //     width: 600px;
        //     height: 400px;
        //     background: var(--primary-color);
        //     border-radius: 12px;
        //     margin: 70px auto auto auto;
        //     form {
        //         padding: 70px 30px;
        //     }
        // }
        .desktop-admin-transactions {
            margin-top: 50px;
            width: 100%;
            header {
                display: flex;
                flex-direction: column;
                span {
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 30px;
                    color: var(--black-color);
                }
                >div {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: 50px;
                    vertical-align: middle;
                    div {
                        font-weight: 500;
                        font-size: 20px;
                        line-height: 30px;
                    }
                }
            }
        }
    }
}
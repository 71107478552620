.user-login {
    width: 100%;
}
.mobile-user-login {
    width: 100%;
    padding: 20px;
    form {
        display: flex;
        flex-direction: column;
        input[type='checkbox'] {
            background-color: var(--white-text) !important;
            padding: 15px 20px 15px 20px;
            outline: none;
            border: 1px solid rgba(113, 41, 153, 0.5);
            color: rgba(113, 41, 153, 0.5);
            font-size: 1rem;
            // margin: 30px 0 15px 0;
            border-radius: 10px;
            width: 15px;
            height: 15px;
        }
        b {
            font-weight: 400;
            font-size: 18px;
            line-height: 27px;
            color: var(--black-color);
            padding: 0 7px;
            vertical-align: text-bottom;
        }
    }
}
.desktop-user-login {
    display: none;
}

@media (min-width: 768px) {
    .mobile-user-login {
        display: none;
    }
    .desktop-user-login {
        display: block;
        padding: 20px;
        form {
            display: flex;
            flex-direction: column;
            input[type='checkbox'] {
                background-color: var(--white-text) !important;
                padding: 15px 20px 15px 20px;
                outline: none;
                border: 1px solid rgba(113, 41, 153, 0.5);
                color: rgba(113, 41, 153, 0.5);
                font-size: 1rem;
                // margin: 30px 0 15px 0;
                border-radius: 10px;
                width: 15px;
                height: 15px;
            }
            b {
                font-weight: 400;
                font-size: 18px;
                line-height: 27px;
                color: var(--black-color);
                padding: 0 7px;
                vertical-align: text-bottom;
            }
        }
    }
}
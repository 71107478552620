.custom-input {
    position: relative;
    color: var(--white-text);
    width: 100%;
    input:not([type='checkbox']) {
        width: 100%;
        background-color: var(--white-text) !important;
        padding: 15px 20px 15px 20px;
        outline: none;
        border: 1px solid rgba(113, 41, 153, 0.5);
        color: rgba(113, 41, 153, 0.5);
        font-size: 1rem;
        margin-bottom: 20px;
        border-radius: 10px;
    }
    input:focus {
        border-color: 1px solid rgba(113, 41, 153, 0.5);
        outline: none;
        // box-shadow: inset 0 1px 1px rgb(0, 0, 0 / 1%), 0 0 8px #61436A;
    }
    input::placeholder {
        color: rgba(113, 41, 153, 0.5);
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }
    label {
        color: var(--white-text);
    }
    label.search {
        color: #D8C9DF;
    }
    input.search {
        width: 100%;
        background-color: var(--white-text) !important;
        padding: 10px 20px;
        outline: none;
        border: none;
        // border: 1px solid rgba(113, 41, 153, 0.5);
        color: rgba(113, 41, 153, 0.5);
        font-size: 1rem;
        margin-top: 20px;
        border-radius: 10px;
    }
    input.search::placeholder {
        color: rgba(113, 41, 153, 0.5);
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }
    input.black {
        width: 100%;
        background-color: var(--white-text) !important;
        padding: 10px 20px;
        outline: none;
        // border: none;
        border: 1px solid rgba(113, 41, 153, 0.4);
        color: rgba(113, 41, 153, 0.5);
        color: var(--black-color);
        font-size: 1rem;
        margin-top: 20px;
        border-radius: 10px;
    }
    label.black {
        color: var(--black-color);
    }
    input.black::placeholder {
        color: rgba(113, 41, 153, 0.5);
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }
}

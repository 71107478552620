.global-filter {
    width: 100%;
}
.mobile-global-filter {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p {
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        color: var(--black-color);
    }
}

.desktop-global-filter {
    display: none;
}

@media (min-width: 768px) {
    .mobile-global-filter {
        display: none;
    }
    .desktop-global-filter {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        // margin-top: 50px;
        vertical-align: middle;
        p {
            font-weight: 400;
            font-size: 18px;
            line-height: 27px;
            color: var(--black-color);
        }
    }
}
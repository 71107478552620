.change-password {
    height: 100vh;
    width: 100%;
    background: var(--lightgrey);
}
.mobile-change-password {
    width: 100%;
    padding: 100px 20px;
    header {
        text-align: center;
        h3 {
            font-weight: 500;
            font-size: 20px;
            line-height: 30px;
            color: var(--black-color);
        }
    }
    .mobile-form {
        width: 100%;
        height: 376px;
        background: var(--primary-color);
        border-radius: 12px;
        margin: 50px auto auto auto;
        form {
            padding: 50px 30px;
        }
    }
}
.desktop-change-password {
    display: none;
}

@media (min-width: 768px) {
    .mobile-change-password {
        display: none;
    }
    .desktop-change-password {
        display: block;
        width: 100%;
        margin: auto;
        padding: 40px 60px 40px 23rem;
        header {
            text-align: center;
            h3 {
                font-weight: 500;
                font-size: 20px;
                line-height: 30px;
                color: var(--black-color);
            }
        }
        .desktop-form {
            width: 554px;
            height: 407px;
            background: var(--primary-color);
            border-radius: 12px;
            margin: 70px auto auto auto;
            form {
                padding: 70px 30px;
            }
        }
    }
}
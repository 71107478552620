.sidebar__container {
    display: none;
}
.sidebar__container--active {
    width: 100px;
}
.mobile-admin-sidebar .active {
    color: white;
    filter: drop-shadow(1px 1px 2px var(--white-text));
    -webkit-filter:  drop-shadow(1px 1px 2px var(--white-text));
}
.mobile-admin-sidebar {
    width: 100%;
    nav {
        position: fixed;
        width: 100vw;
        background-color: var(--primary-color);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 1em;
        z-index: 9999;
        button {
            outline: none;
            border: none;
            background: none;
            color: var(--white-text);
        }
    }
    section .active {
        color: #fff;
    }
    section {
        display: block;
        position: fixed;
        left: 0;
        top: 4.5em;
        font-size: 14px;
        line-height: 16px;
        background-color: var(--primary-color);
        width: 272px;
        height: 100%;
        z-index: 9999;
        padding: 10px 20px;
        header {
            width: 100%;
            img {
                width: 50px;
                height: 50px;
            }
            hr {
                width: 100%;
                border: 1px solid rgba(255, 255, 255, 0.2);
                margin: 20px auto;
            }
        }
        .nav-links {
            color: rgba(255, 255, 255, 0.4);
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 20px;
            text-decoration: none;
            padding: 5px;
            text-decoration: none;
            > span {
                color: rgba(255, 255, 255, 0.4);
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                /* margin-top: 5px; */
            }
            > .icon-container {
                color: rgba(255, 255, 255, 0.4);
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 5px;
            }
        }
        footer {
            position: fixed;
            bottom: 0;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-start;
            row-gap: 20px;
            // padding-top: 7rem;
            margin: 0 5px;
            .desktop-admin-user-profile {
                display: flex;
                justify-content: center;
                align-items: center;
                column-gap: 20px;
                img {
                    border-radius: 50%;
                    width: 50px;
                    height: 50px;
                }
                span {
                    color: #fff;
                    font-family: var(--fontFamily);
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 19px;
                    text-transform: capitalize;
                }
                .mobile-admin-logout-button {
                    width: 100%;
                }
            }
        }
    }
}
.desktop-admin-sidebar {
    display: none;
}

@media (min-width: 768px) {
    .mobile-admin-sidebar {
        display: none;
    }
    .desktop-admin-sidebar .active {
        color: white;
        filter: drop-shadow(1px 1px 2px var(--white-text));
        -webkit-filter:  drop-shadow(1px 1px 2px var(--white-text));
    }
    .desktop-admin-sidebar {
        display: block;
        width: 300px;
        height: 100vh;
        background-color: var(--primary-color);  
        position: fixed;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 40px 20px;
        header {
            width: 100%;
            img {
                width: 50px;
                height: 50px;
            }
            hr {
                width: 100%;
                border: 1px solid rgba(255, 255, 255, 0.2);
                margin: 20px auto;
            }
        }
        .nav-links {
            color: rgba(255, 255, 255, 0.4);
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 10px;
            text-decoration: none;
            > span {
                color: rgba(255, 255, 255, 0.4);
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                /* margin-top: 5px; */
            }
            > .icon-container {
                color: rgba(255, 255, 255, 0.4);
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 5px;
            }
        }
        footer {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            // row-gap: 20px;
            padding-top: 300px;
            width: 100%;
            .desktop-admin-user-profile {
                display: flex;
                justify-content: center;
                align-items: center;
                column-gap: 20px;
                > img {
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                }
                > span {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    color: var(--white-text);
                    text-transform: capitalize;
                }
            }
            .desktop-admin-logout-button {
                width: 100%;
                // height: 44px;
            }
        }
    }
}
.login {
    padding: 20px;
    width: 100%;
    background: var(--primary-color);
    height: 100vh;
}
.mobile-login {
    width: 100%;
    header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        img {
            width: 80px;
            height: 80px;
        }
        p {
            font-weight: 600;
            font-size: 24px;
            line-height: 36px;
            color: var(--white-text);
        }
    }
    .mobile-login-form {
        height: 450px;
        display: block;
        margin: 25px auto auto auto;
        padding: 16px;
        background: var(--white-text);
        border-radius: 12px;
        span {
            display: flex;
            justify-content: space-between;
            align-items: center;
            // gap: 25px;
            padding: 20px 8px;
            button {
                width: 112px;
                height: 24px;
                background: none;
                font-weight: 500;
                font-size: 12px;
                line-height: 20px;
                color: var(--primary-color);
                border: none;
                outline: none;
                cursor: pointer;
                white-space: nowrap;
                img {
                    vertical-align: top;
                }
            }   
            button.active {
                background: linear-gradient(286.47deg, #712999 80.38%, rgba(113, 41, 153, 0) 109.18%);
                filter: drop-shadow(1px 1px 4px rgba(0, 0, 0, 0.25));
                border-radius: 10px;
                width: 150px;
                height: 40px;
                color: var(--white-text);
                font-weight: 500;
                font-size: 12px;
                line-height: 20px;
                > *{
                    color: white;
                    filter: drop-shadow(1px 1px 2px var(--white-text));
                    -webkit-filter:  drop-shadow(1px 1px 2px var(--white-text));
                }
            }
        }
    }
}
.desktop-login {
    display: none;
}

@media (min-width: 768px) {
    .mobile-login {
        display: none;
    }
    .desktop-login {
        display: block;
        width: 100%;
        header {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 10px;
            img {
                width: 100px;
                height: 100px;
            }
            p {
                font-weight: 600;
                font-size: 28px;
                line-height: 42px;
                color: var(--white-text);
            }
        }
        .desktop-login-form {
            width: 554px;
            height: 507px;
            display: block;
            margin: 50px auto auto auto;
            padding: 20px;
            background: var(--white-text);
            border-radius: 12px;
            span {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 100px;
                padding: 20px;
                button {
                    width: 190px;
                    height: 40px;
                    background: none;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 27px;
                    color: var(--primary-color);
                    border: none;
                    outline: none;
                    cursor: pointer;
                    img {
                        vertical-align: top;
                    }
                }   
                button.active {
                    background: linear-gradient(286.47deg, #712999 80.38%, rgba(113, 41, 153, 0) 109.18%);
                    filter: drop-shadow(1px 1px 4px rgba(0, 0, 0, 0.25));
                    border-radius: 10px;
                    width: 190px;
                    height: 40px;
                    color: var(--white-text);
                    > *{
                        color: white;
                        filter: drop-shadow(1px 1px 2px var(--white-text));
                        -webkit-filter:  drop-shadow(1px 1px 2px var(--white-text));
                    }
                }
            }
        }
    }
}
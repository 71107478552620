.create-user {
    min-height: 100vh;
    width: 100%;
    background-color: var(--dashboard-background);
}
.mobile-create-user {
    width: 100%;
    padding: 100px 20px;
    form > label {
        display: flex;
        flex-direction: column;
        gap: 5px;
        select {
            width: 100%;
            background-color: var(--white-text) !important;
            padding: 15px 20px 15px 20px;
            outline: none;
            border: 1px solid rgba(113, 41, 153, 0.5);
            // color: rgba(113, 41, 153, 0.5);
            color: var(--black-color);
            font-size: 1rem;
            margin-bottom: 20px;
            border-radius: 10px;
        }
    }
}
.desktop-create-user {
    display: none;
}

@media (min-width: 768px) {
    .mobile-create-user {
        display: none;
    }
    .desktop-create-user {
        display: block;
        width: 100%;
        margin: auto;
        padding: 40px 60px 40px 23rem;
        header {
            text-align: left;
            margin-bottom: 50px;
            h3 {
                font-weight: 500;
                font-size: 20px;
                line-height: 30px;
                color: var(--black-color);
            }
        }
        form {
            margin-top: 100px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;
            align-items: stretch;
            // width: 100%;
            // flex: 50%;
            .input-column-one {
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                width: 48%;
            }
            .input-column-two {
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                width: 48%;
                section {
                    margin: 10px 0;
                }
            }
            label {
                display: flex;
                flex-direction: column;
                gap: 5px;
                select {
                    width: 100%;
                    background-color: var(--white-text) !important;
                    padding: 15px 20px 15px 20px;
                    outline: none;
                    border: 1px solid rgba(113, 41, 153, 0.5);
                    // color: rgba(113, 41, 153, 0.5);
                    color: var(--black-color);
                    font-size: 1rem;
                    margin-bottom: 20px;
                    border-radius: 10px;
                }
            }
        }
    }
}